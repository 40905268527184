import React, { useMemo } from "react";
import SchemaFormPage, { SchemaFormParameter } from "components/SchemaFormPage";
import { useSignUp } from "hooks/auth";
import { shopData } from "common/models/shop";
import ConsumerLayout from "components/ConsumerLayout";
import { CONSUMER } from "..";
import { resolveRoute, useTypedNavigate } from "pages";
import getSchema from "./schema";
import { useClientDoc, useShopDoc } from "models/hook";
import { useFormatter, useStringIntl } from "hooks/intl";
import { ConsumerLocaleSelector } from "hooks/localeSelector";
import { checkTimeRange } from "utils/time";
import { updateContractConfirmed, useContractConfirmed } from "hooks/contract";
import { useContextLocale } from "components/LocaleProvider";
import { getLogParams, trackingLog, useTrackingLog } from "utils/log";
import {
  MaintenaceMessage,
  isMaintenanceMode,
} from "components/MaintenanceMessage";
import { Navigate } from "react-router-dom";

export const CONSUMER_ENTRY = CONSUMER.child("/entry", ConsumerEntry);

function ConsumerEntry({
  shopId,
  shopData: injectedShopData,
}: {
  shopId?: string;
  shopData?: Partial<shopData> | null;
}) {
  useTrackingLog("entryOpen", { shopId }, !!injectedShopData);
  const { data: systemSetting } = useClientDoc({ clientId: "default" });
  const { data: shopData, loading } =
    injectedShopData !== undefined
      ? { data: injectedShopData || undefined, loading: false }
      : useShopDoc({ shopId });

  if (!injectedShopData && shopData?.redirectURL) {
    (window as Window).location = shopData?.redirectURL;
  }

  const { data: clientData } = useClientDoc({ clientId: shopData?.clientId });
  const { data: defaultClientData } = useClientDoc({ clientId: "default" });
  const [signUp, data, running, error] = useSignUp();
  const { formatMessage, formatString } = useFormatter();
  const s = useStringIntl();

  const contractProfile = {
    issuerId: shopData?.shopId || "",
    contractTitle: formatMessage("PrivacyPolicy.Title"),
    contractContent:
      s(clientData.privacyPolicy || defaultClientData.privacyPolicy) || "",
  };

  const [locale, setLocale] = useContextLocale();

  const parameter: SchemaFormParameter = {
    title: !loading
      ? formatString(shopData?.entryTitle, "Entry.Title")
      : undefined,
    subtitle: !loading
      ? formatString(shopData?.entrySubtitle, "Entry.Subtitle")
      : undefined,
    schema: getSchema(contractProfile),
    edit: {
      title: formatMessage("Entry.Submit"),
      handler: async (value) => {
        if (!shopId) {
          return;
        }
        updateContractConfirmed(
          {
            ...contractProfile,
            email: value.email,
          },
          value.contractConfirm
        );

        trackingLog("entrySubmit", {
          shopId,
          clientId: shopData?.clientId,
          surveyId: shopData?.surveyId,
          locale,
        });
        const data = {
          email: value.email,
          path: resolveRoute("CONSUMER_MAIL_AUTH", { shopId }),
          shopId,
          locale,
          contractId: value.contractConfirm,
        };
        Object.assign(data, getLogParams());
        await signUp(data);
      },
      confirm: false,
    },
  };
  const completedParameter: SchemaFormParameter = {
    title: formatMessage("EntryComplete.Title"),
    schema: {
      schemaType: "message",
      text: formatMessage("EntryComplete.Text"),
    },
  };
  const isEnquatePublished = useMemo(() => {
    return checkTimeRange(shopData?.enqueteStartAt, shopData?.enqueteEndAt);
  }, [shopData]);

  if (shopData?.enableRedirection && shopData?.isAuthSkipped && shopId) {
    return (
      <Navigate to={resolveRoute("CONSUMER_PUBLIC_ENQUETE", { shopId })} />
    );
  }

  if (loading) {
    return <></>;
  }

  if (isMaintenanceMode(systemSetting)) {
    return (
      <ConsumerLayout shopId={shopId} shopData={shopData} skipAuthentication>
        <MaintenaceMessage systemSetting={systemSetting} />
      </ConsumerLayout>
    );
  }

  return (
    <ConsumerLayout shopId={shopId} shopData={shopData} skipAuthentication>
      <div className="wide-form">
        {(clientData?.availableLocales?.length || 0) > 1 ? (
          <div
            className="adjust-width"
            style={{ textAlign: "center", marginTop: "10px" }}
          >
            <ConsumerLocaleSelector
              locales={clientData?.availableLocales || []}
            />
          </div>
        ) : undefined}
        {!isEnquatePublished ? (
          <div
            className="adjust-width"
            style={{ textAlign: "center", marginTop: "10px" }}
          >
            <p style={{ fontSize: "24px", fontWeight: "bold" }}>
              {formatMessage("Enquete.NotAvailable")}
            </p>
          </div>
        ) : (
          <SchemaFormPage
            data={{}}
            parameter={!data ? parameter : completedParameter}
            loading={!!running}
          />
        )}
      </div>
    </ConsumerLayout>
  );
}
export default ConsumerEntry;
