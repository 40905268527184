import React from "react";
import { ObjectSchema } from "schemaComponents";
import getListSchema from "./schema";
import getSchema from "../item/schema";
import { timestampsProperties, mergeSchema } from "schemaComponents/util";
import { clientDomain } from "utils/domain";
import { resolveRoute } from "pages";
import { PropertyParameter } from "schemaComponents/components/object";
import { is } from "date-fns/locale";

export const getDataTransferSchema = ({
  clientId,
  isMaster,
  isAuthSkipped,
  isUpload = false,
  isDownload = false,
}: {
  clientId: string;
  isMaster: boolean;
  isAuthSkipped: boolean;
  isUpload?: boolean;
  isDownload?: boolean;
}): ObjectSchema => {
  return mergeSchema({ schemaType: "object", properties: [] }, [
    ...(isUpload
      ? ([
          {
            title: "事業所ID",
            propertyName: "shopId",
            schema: {
              uiType: "normal",
              schemaType: "externalKey",
              resourcePath: "/shop",
              titleKey: "shopName",
              query: { filter: { clientId } },
            },
          },
        ] as PropertyParameter[])
      : []),
    {
      title: "事業所ID",
      propertyName: "shopId",
      schema: {
        schemaType: "text",
        required: true,
      },
    },
    ...getListSchema({ clientId }).properties,
    ...(
      getSchema({
        isEditing: false,
        clientId,
        isMaster,
        isAuthSkipped,
        isDownload,
      }) as ObjectSchema
    ).properties,
    ...timestampsProperties,
  ]);
};

export const getDataTransferSchemaForClient = ({
  clientId,
}: {
  clientId: string;
}): ObjectSchema => {
  return {
    schemaType: "object",
    properties: [
      {
        title: "事業所名",
        propertyName: "shopName",
        schema: {
          schemaType: "textIntl",
          required: true,
        },
      },
      {
        title: "ロゴ",
        propertyName: "logoURL",
        schema: {
          schemaType: "image",
          maxHeight: 30,
        },
      },
      {
        title: "調査",
        propertyName: "surveyId",
        schema: {
          uiType: "normal",
          schemaType: "externalKey",
          resourcePath: "/survey",
          titleKey: "surveyName",
          query: { filter: { clientId } },
        },
      },
      {
        title: "リワード条件",
        propertyName: "rewardCondition",
        schema: {
          schemaType: "array",
          defaultValue: {
            probability: null,
            rewardId: null,
            animationId: null,
          },
          item: {
            schemaType: "object",
            // uiType: "dialog",
            properties: [
              {
                title: "リワード",
                propertyName: "rewardId",
                schema: {
                  schemaType: "externalKey",
                  uiType: "normal",
                  resourcePath: "/reward",
                  titleKey: "rewardName",
                  query: { filter: { clientId } },
                },
              },
              {
                title: "確率",
                propertyName: "probability",
                schema: {
                  schemaType: "float",
                },
              },

              {
                title: "当選アニメーションパターン",
                propertyName: "animationId",
                schema: {
                  schemaType: "selector",
                  uiType: "normal",
                  options: [
                    {
                      title: "1等 (ラッキーセブン)",
                      value: 1,
                    },
                    {
                      title: "2等 (赤い果実)",
                      value: 2,
                    },
                    {
                      title: "3等 (ベル)",
                      value: 3,
                    },
                    {
                      title: "4等 (青い果実)",
                      value: 4,
                    },
                    {
                      title: "5等 (オレンジ)",
                      value: 5,
                    },
                    {
                      title: "はずれ（そろわない）",
                      value: 0,
                    },
                  ],
                },
              },
            ],
          },
        },
      },
      {
        title: "URL",
        propertyId: "url",
        schema: {
          schemaType: "url",
          readOnly: true,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          calc: (item: any) => {
            const { shopId, isAuthSkipped } = item;
            return isAuthSkipped
              ? shopId &&
                  `${clientDomain}${resolveRoute("CONSUMER_PUBLIC_ENQUETE", {
                    shopId,
                  })}`
              : shopId &&
                  `${clientDomain}${resolveRoute("CONSUMER_ENTRY", {
                    shopId,
                  })}`;
          },
        },
      },
      {
        title: "外部リダイレクトURL",
        propertyName: "redirectURL",
        schema: {
          schemaType: "text",
        },
      },
      {
        title: "アンケート開始日時",
        propertyName: "enqueteStartAt",
        schema: {
          schemaType: "datetime",
        },
      },
      {
        title: "アンケート終了日時",
        propertyName: "enqueteEndAt",
        schema: {
          schemaType: "datetime",
        },
      },
      {
        title: "住所",
        propertyName: "shopAddress",
        schema: {
          schemaType: "text",
        },
      },
      {
        title: "地域",
        propertyName: "region",
        schema: {
          schemaType: "text",
        },
      },
    ],
  };
};
